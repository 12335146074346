import React from 'react';
import Icon from '../../images/icon-03.svg';

function DashboardCard03({ profit, products }) {

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          <img src={Icon} width="32" height="32" alt="Icon 03" />
        </header>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">Profit</h2>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
        { products.length === 0 ? 'All Products' : products.length === 1 ? products[0] : `${products[0]}+` }
        </div>
        <div className="flex items-start mb-3">
          <div className="text-3xl font-bold text-gray-800 mr-2">${profit}</div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard03;
