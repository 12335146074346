import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd';

import Axios from '../api/axios';
import CONSTANTS from '../constants';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import FilterButton from '../partials/actions/FilterButton';
import Datepicker from '../partials/actions/Datepicker';
import DashboardCard01 from '../partials/dashboard/DashboardCard01';
import DashboardCard02 from '../partials/dashboard/DashboardCard02';
import DashboardCard03 from '../partials/dashboard/DashboardCard03';
import DashboardCard04 from '../partials/dashboard/DashboardCard04';
import DashboardCard05 from '../partials/dashboard/DashboardCard05';
import DashboardCard07 from '../partials/dashboard/DashboardCard07';
import DashboardCard10 from '../partials/dashboard/DashboardCard10';
import DashboardCard14 from '../partials/dashboard/DashboardCard14';

function Dashboard(props) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [ready, setReady] = useState(false);
  const [stats, setStats] = useState({});
  const [products, setProducts] = useState(['katariosms', 'keenpass']);
  const [dates, setDates] = useState({ dateBegin: null, dateEnd: null });


  const setFilters = (products_) => {
    setProducts(products_);
  }

  const setDateInterval = (dateBegin, dateEnd) => {
    setDates({ dateBegin, dateEnd })
  }

  useEffect(() => {
    loadStats(true);
  }, [products, dates]);

  const loadStats = (showLoading) => {
    const data = {};
    if (products.length) {
      data.products = products;
    }

    if (dates.dateBegin && dates.dateEnd) {
      data.dateBegin = dates.dateBegin;
      data.dateEnd = dates.dateEnd;
    }

    if (showLoading) {
      message.loading('Loading', 1);
    }

    Axios({
      method: 'post',
      url: '/auth/dashboard',
      headers: { 'x-auth': props.authId },
      data,
    })
      .then(({ data }) => {
        const {
          totalVisits,
          conversions,
          profit,
          balance,
          katariosmsLink,
          keenpassLink,
          latestReferrals,
          topChannels,
          revenuesData,
          chartUnit,
        } = data;

        setStats({
          totalVisits,
          conversions,
          profit,
          balance,
          katariosmsLink,
          keenpassLink,
          latestReferrals,
          topChannels,
          revenuesData,
          chartUnit,
        });
        setReady(true);
      })
      .catch(({ response }) => {

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.WEBSITE_URL}`;
          } else if (status === 400) {
            message.error(response.data.errors[0].msg, 5);
          } else if (status === 429) {
            message.error('Please slow down! Wait 2 minutes and try again', 5);
          } else {
            message.error(response.data.message, 5);
          }
        } else {
          message.error('Network error!', 5);
        }

      });
  }

  useEffect(() => {
    loadStats();
  }, []);

  return (

    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header me={props.me} notifications={props.notifications} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} logout={props.logout} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <WelcomeBanner me={props.me} />

            {
              ready && (
                <>
                  {/* Dashboard actions */}
                  <div className="sm:flex sm:justify-end sm:items-center mb-8">

                    {/* Right: Actions */}
                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                      {/* Filter button */}
                      <FilterButton setFilters={setFilters} />
                      {/* Datepicker built with flatpickr */}
                      <Datepicker setDateInterval={setDateInterval} />
                      {/* Add view button */}
                      <Link to="/transactions" className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                        <svg className="w-4 h-4 fill-current opacity-90 flex-shrink-0" viewBox="0 0 20 20">
                          <path fill="currentColor" d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" />
                        </svg>
                        <span className="hidden xs:block ml-2">Withdraw</span>
                      </Link>
                    </div>

                  </div>

                  {/* Cards */}
                  <div className="grid grid-cols-12 gap-3">
                    {/* Visit */}
                    <DashboardCard01 visits={stats.totalVisits} products={products} />
                    {/* Conversion */}
                    <DashboardCard02 conversions={stats.conversions} products={products} />
                    {/* Profit */}
                    <DashboardCard03 profit={stats.profit} products={products} />
                    {/* Balance */}
                    <DashboardCard14 balance={stats.balance} />
                  </div>
                  <div className="grid grid-cols-12 gap-4 mt-4">

                    {/* Chart */}
                    <DashboardCard05 revenuesData={stats.revenuesData} unit={stats.chartUnit} />
                    {/* Affiliation Links */}
                    <DashboardCard04 katariosmsLink={stats.katariosmsLink} keenpassLink={stats.keenpassLink} />
                    {/* Table (Top Sources) */}
                    <DashboardCard07 topChannels={stats.topChannels} />
                    {/* Latest Affiliates (Customers) */}
                    <DashboardCard10 latestReferrals={stats.latestReferrals} />

                  </div>
                </>
              )
            }

          </div>
        </main>

      </div>
    </div>
  );
}

export default Dashboard;