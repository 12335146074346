import React, { useState, useEffect } from 'react';
import { message } from 'antd';

import Axios from '../api/axios';
import CONSTANTS from '../constants';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import TextBanner from '../partials/dashboard/TextBanner';
import ReferralsTable from '../partials/ReferralsTable';

function Referrals(props) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [referrals, setReferrals] = useState([]);

  const loadReferrals = (showLoading) => {

    if (showLoading) {
      message.loading('Loading', 1);
    }

    Axios({
      method: 'get',
      url: '/auth/referrals',
      headers: { 'x-auth': props.authId },
    })
      .then(({ data }) => {
        const {
          referrals,
        } = data;

        setReferrals(referrals);
      })
      .catch(({ response }) => {

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.WEBSITE_URL}`;
          } else if (status === 400) {
            message.error(response.data.errors[0].msg, 5);
          } else if (status === 429) {
            message.error('Please slow down! Wait 2 minutes and try again', 5);
          } else {
            message.error(response.data.message, 5);
          }
        } else {
          message.error('Network error!', 5);
        }

      });
  }

  useEffect(() => {
    loadReferrals();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header me={props.me} notifications={props.notifications} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} logout={props.logout} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <TextBanner title={'Referrals. 🎉'} />

            <div className="mt-4">

              <ReferralsTable
                referrals={referrals}
              />
            </div>

          </div>
        </main>

      </div>
    </div>
  );
}

export default Referrals;