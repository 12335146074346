import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CONSTANTS from '../constants';

function Withdraw({ requestWithdrawal, balance }) {

  const [amount, setAmount] = useState(CONSTANTS.MINIMUM_WITHDRAWAL);
  const [disabled, setDisabled] = useState(false);
  const [code, setCode] = useState('');

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 max-w-md">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-medium text-gray-800 text-base">Withdraw to your <Link className="text-indigo-600" to="/settings">withdrawal method</Link> (${balance})</h2>
      </header>
      <div className="mb-2 px-8 pb-2">

        <div className="mb-2">
          <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
          Amount (min ${CONSTANTS.MINIMUM_WITHDRAWAL}) *
          </label>
          <input
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder=""
            autoFocus={true}
            type="number"
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value, 10))}
            min={CONSTANTS.MINIMUM_WITHDRAWAL}
            max={balance}
          />
        </div>

        <div className="mb-2">
          <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
            Two Factor Authentication Code *
          </label>
          <input
            onChange={(e) => setCode(e.target.value)}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            id="name"
            type="text"
            placeholder=""
            autoFocus={true}
            value={code}
          />
        </div>

        <div className="flex lg:space-x-3 pt-3 bg-white mt-2 justify-end">
          <button
            disabled={disabled || amount < CONSTANTS.MINIMUM_WITHDRAWAL || amount > balance || !code}
            onClick={() => requestWithdrawal(amount, true, setDisabled, code)}
            className={`btn bg-indigo-500 hover:bg-indigo-600 text-white h-11 ${(disabled || amount < CONSTANTS.MINIMUM_WITHDRAWAL || amount > balance || !code) ? 'cursor-not-allowed' : ''}`}
          >
            <span className="block">Withdraw</span>
          </button>
        </div>

      </div>
    </div>
  );
}

export default Withdraw;
