import React, { useState, useEffect } from 'react';
import { message } from 'antd';

import Axios from '../api/axios';
import CONSTANTS from '../constants';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import TextBanner from '../partials/dashboard/TextBanner';
import TransactionsTable from '../partials/TransactionsTable';
import Withdraw from '../partials/Withdraw';


function Transactions(props) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [balance, setbalance] = useState(0);

  const loadTransactions = (showLoading) => {

    if (showLoading) {
      message.loading('Loading', 1);
    }

    Axios({
      method: 'get',
      url: '/auth/transactions',
      headers: { 'x-auth': props.authId },
    })
      .then(({ data }) => {
        const {
          transactions,
          balance,
        } = data;

        setTransactions(transactions);
        setbalance(balance);
      })
      .catch(({ response }) => {

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.WEBSITE_URL}`;
          } else if (status === 400) {
            message.error(response.data.errors[0].msg, 5);
          } else if (status === 429) {
            message.error('Please slow down! Wait 2 minutes and try again', 5);
          } else {
            message.error(response.data.message, 5);
          }
        } else {
          message.error('Network error!', 5);
        }

      });
  }

  const requestWithdrawal = (amount, showLoading, setDisabled, code) => {

    if (parseInt(amount, 10) > balance || parseInt(amount, 10) < CONSTANTS.MINIMUM_WITHDRAWAL) {
      return message.error('You can\'t withdraw this amount!');
    }

    setDisabled(true);
    if (showLoading) {
      message.loading('Loading', 1);
    }

    Axios({
      method: 'post',
      url: '/auth/withdraw',
      headers: { 'x-auth': props.authId },
      data: {
        amount: parseInt(amount),
        token: code,
      },
    })
      .then(({ data }) => {
        setDisabled(false);
        const {
          transactions,
          balance,
        } = data;

        setTransactions(transactions);
        setbalance(balance);
      })
      .catch(({ response }) => {
        setDisabled(false);

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.WEBSITE_URL}`;
          } else if (status === 400) {
            message.error(response.data.errors[0].msg, 5);
          } else if (status === 429) {
            message.error('Please slow down! Wait 2 minutes and try again', 5);
          } else {
            message.error(response.data.message, 5);
          }
        } else {
          message.error('Network error!', 5);
        }

      });
  }

  useEffect(() => {
    loadTransactions();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header me={props.me} notifications={props.notifications} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} logout={props.logout} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <TextBanner title={'Transactions. 💵'} />

            <div className="mt-4">

              <Withdraw requestWithdrawal={requestWithdrawal} balance={balance} />

              <TransactionsTable
                transactions={transactions}
              />

            </div>

          </div>
        </main>

      </div>
    </div>
  );
}

export default Transactions;