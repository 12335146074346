import React, { useRef } from 'react';
import { message } from 'antd';

function DashboardCard04({ katariosmsLink, keenpassLink }) {

  const katariosmsInputEl = useRef(null);
  const keenpassInputEl = useRef(null);

  const selectContents = (el) => {
    el.current.select();
    el.current.blur();
    message.success('Copied!');
  }

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
    }

    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text, el) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function () {
    }, function (err) {});
    selectContents(el);
  }


  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Referral Links</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <div className="px-6 mb-2">
        <div className="xl:flex lg:space-x-3 pt-3 pb-2 px-4 bg-white shadow-lg rounded-sm border border-gray-200 mt-2">
          <p className="font-semibold text-indigo-500 w-28 leading-10">Katario SMS</p>
          <input
            ref={katariosmsInputEl}
            onFocus={(event) => event.target.select()}
            className="md:flex-grow text-sm xl:text-base text-center h-10" readOnly value={katariosmsLink}
          />
          <p className="cursor-pointer hover:bg-gray-200 p-2 rounded-full h-10 w-10 inline-block align-middle"
            onClick={() => {
              copyTextToClipboard(katariosmsLink, katariosmsInputEl);
            }}
          >
            <svg className="h-6 w-6" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
            </svg>
          </p>
        </div>
        <div className="xl:flex lg:space-x-3 pt-3 pb-2 px-4 bg-white shadow-lg rounded-sm border border-gray-200 mt-2">
          <p className="font-semibold text-indigo-500 w-28 leading-10">KeenPass</p>
          <input
            ref={keenpassInputEl}
            onFocus={(event) => event.target.select()}
            className="md:flex-grow text-sm xl:text-base text-center h-10" readOnly value={keenpassLink}
          />
          <p className="cursor-pointer hover:bg-gray-200 p-2 rounded-full h-10 w-10 inline-block align-middle"
            onClick={() => {
              copyTextToClipboard(keenpassLink, keenpassInputEl);
            }}
          >
            <svg className="h-6 w-6" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
            </svg>
          </p>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard04;
