import React, { useState } from 'react';
import { message } from 'antd';

import Axios from '../api/axios';
import CONSTANTS from '../constants';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import TextBanner from '../partials/dashboard/TextBanner';
import WithdrawalMethod from '../partials/WithdrawalMethod';
import TwoAuthenticator from '../partials/TwoAuthenticator';


function Settings(props) {

  //  console.log(props.withdrawalDetails);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [withdrawalDetails, setWithdrawalDetails] = useState(props.withdrawalDetails);

  const updateWithdrawalDetails = ({
    iwithdrawalName,
    iwithdrawalEmail,
    iwithdrawalMethod,
    iwithdrawalRoutingNumber,
    iwithdrawalAccountNumber,
    code
  }) => {

    if (!iwithdrawalMethod || !code) {
      return message.error('Please provide all required informations!');
    }

    switch (iwithdrawalMethod) {
      case 'payoneer':
      case 'paypal': {
        if (!iwithdrawalEmail || !iwithdrawalEmail.includes('@')) {
          return message.error('Please provide all required informations!');
        }
        break;
      }
      case 'bank': {
        if (!iwithdrawalName || !iwithdrawalRoutingNumber || !iwithdrawalAccountNumber) {
          return message.error('Please provide all required informations!');
        }
        break;
      }
      default: { }
    }

    message.loading('Loading', 1);

    Axios({
      method: 'post',
      url: '/auth/update',
      headers: { 'x-auth': props.authId },
      data: {
        withdrawalName: iwithdrawalName,
        withdrawalEmail: iwithdrawalEmail,
        withdrawalMethod: iwithdrawalMethod,
        withdrawalRoutingNumber: iwithdrawalRoutingNumber,
        withdrawalAccountNumber: iwithdrawalAccountNumber,
        token: code,
      },
    })
      .then(({ data }) => {

        const {
          withdrawalDetails
        } = data;

        setWithdrawalDetails(withdrawalDetails);

        message.success('Updated!');
      })
      .catch(({ response }) => {

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.WEBSITE_URL}`;
          } else if (status === 400) {
            message.error(response.data.errors[0].msg, 5);
          } else if (status === 429) {
            message.error('Please slow down! Wait 2 minutes and try again', 5);
          } else {
            message.error(response.data.message, 5);
          }
        } else {
          message.error('Network error!', 5);
        }

      });
  }

  const setupTwoFactor = (code) => {

    if (!code) {
      return message.error('The two factor authentication code is required!');
    }

    message.loading('Processing..', 1);

    Axios({
      method: 'post',
      url: '/auth/setup',
      headers: { 'x-auth': props.authId },
      data: {
        token: code,
      },
    })
      .then(() => {

        window.location.reload();

      })
      .catch(({ response }) => {

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.WEBSITE_URL}`;
          } else if (status === 400) {
            message.error(response.data.errors[0].msg, 5);
          } else if (status === 429) {
            message.error('Please slow down! Wait 2 minutes and try again', 5);
          } else {
            message.error(response.data.message, 5);
          }
        } else {
          message.error('Network error!', 5);
        }

      });
  };

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header me={props.me} notifications={props.notifications} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} logout={props.logout} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <TextBanner title={'Settings. ⚙️'} />

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-end sm:items-center mb-8">

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              </div>

            </div>

            <div className="mt-4">
              {props.me.twoAuthComplete || <TwoAuthenticator setupTwoFactor={setupTwoFactor} me={props.me} /> }
              {props.me.twoAuthComplete && props.withdrawalDetails.withdrawalMethod && <WithdrawalMethod updateWithdrawalDetails={updateWithdrawalDetails} {...props.withdrawalDetails} /> }
            </div>

          </div>
        </main>

      </div>
    </div>
  );
}

export default Settings;