import React from 'react';

import { MDBDataTable } from 'mdbreact';

const TransactionsTable = ({ transactions }) => {
  const data = {
    columns: [
      {
        label: 'Amount',
        field: 'amount',
        sort: 'asc',
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
      },
      {
        label: 'Message',
        field: 'messagex',
        sort: 'asc',
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
      },
    ],
    rows: transactions.map((c) => ({ ...c, date: new Date(c.date).toLocaleString(), amount: `$${c.amount}` })),
  };

  return (
    <div>
      <MDBDataTable
        striped
        bordered
        hover
        small
        exportToCSV
        noRecordsFoundLabel="No data available"
        responsive
        displayEntries={false}
        data={data}
        entries={20}
        noBottomColumns
        className="text-gray-700 font-medium"
      />
    </div>
  );
}

export default TransactionsTable;