import React, { useState } from 'react';

function WithdrawalMethod({ updateWithdrawalDetails, withdrawalMethod, withdrawalEmail, withdrawalName, withdrawalRoutingNumber, withdrawalAccountNumber }) {

  const [iwithdrawalMethod, setIwithdrawalMethod] = useState(withdrawalMethod);
  const [iwithdrawalEmail, setIwithdrawalEmail] = useState(withdrawalEmail);
  const [iwithdrawalRoutingNumber, setIwithdrawalRoutingNumber] = useState(withdrawalRoutingNumber);
  const [iwithdrawalName, setIwithdrawalName] = useState(withdrawalName);
  const [iwithdrawalAccountNumber, setIwithdrawalAccountNumber] = useState(withdrawalAccountNumber);
  const [code, setCode] = useState('');

  const capitalize = (word) => word[0].toUpperCase() + word.slice(1);

  const isEnabled = () => {
    if (!code) return false;

    switch (iwithdrawalMethod) {
      case 'payoneer':
      case 'paypal': {
        return iwithdrawalEmail && iwithdrawalEmail.includes('@');
      }
      case 'bank': {
        return iwithdrawalName && iwithdrawalRoutingNumber && iwithdrawalAccountNumber;
      }
      default: {
        return false;
      }
    }
  };

  if (!withdrawalMethod || !iwithdrawalMethod) {
    return <></>;
  } else {
    return (
      <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 max-w-lg mx-auto">
        <header className="px-5 py-4 border-b border-gray-100">
          <h2 className="font-medium text-gray-800 text-base">Update your withdrawal details</h2>
        </header>
        <div className="mb-2 pb-2 px-6">
          <div className="mb-2">
            <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
              Full Name *
            </label>
            <input
              onChange={(e) => setIwithdrawalName(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              id="name"
              type="text"
              placeholder="John doe"
              autoFocus={true}
              value={iwithdrawalName}
              // defaultValue={withdrawalName}
            />
          </div>
  
          <div className="mb-2">
            <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
              Method *
            </label>
            <select
              onChange={(e) => setIwithdrawalMethod(e.target.value)}
              value={iwithdrawalMethod}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            >
              <option key={1} value="paypal">Paypal</option>
              <option key={2} value="payoneer">Payoneer</option>
              <option key={3} value="bank">Bank (Checking Account)</option>
            </select>
          </div>
  
          {iwithdrawalMethod !== 'bank' && <div className="mb-2">
            <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
              {capitalize(iwithdrawalMethod)} Email *
            </label>
            <input
              onChange={(e) => setIwithdrawalEmail(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              id="name"
              type="text"
              placeholder="johndoe@gmail.com"
              autoFocus={true}
              value={iwithdrawalEmail}
              // defaultValue={withdrawalEmail}
            />
          </div>}
  
          {iwithdrawalMethod === 'bank' &&
            <>
              <div className="mb-2">
                <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
                  Bank Routing Number *
                </label>
                <input
                  onChange={(e) => setIwithdrawalRoutingNumber(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  id="name"
                  type="text"
                  placeholder="XXXX"
                  autoFocus={true}
                  value={iwithdrawalRoutingNumber}
                  // defaultValue={withdrawalRoutingNumber}
                />
              </div>
  
              <div className="mb-2">
                <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
                  Bank Account Number *
                </label>
                <input
                  onChange={(e) => setIwithdrawalAccountNumber(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  id="name"
                  type="text"
                  placeholder="XXXX"
                  autoFocus={true}
                  value={iwithdrawalAccountNumber}
                  // defaultValue={withdrawalAccountNumber}
                />
              </div>
            </>
  
          }
  
            <div className="mb-2">
              <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
                Two Factor Authentication Code *
              </label>
              <input
                onChange={(e) => setCode(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                id="name"
                type="text"
                placeholder=""
                autoFocus={true}
                value={code}
              />
            </div>
  
            <div className="flex lg:space-x-3 pt-3 bg-white mt-2 justify-end">
              <button
                disabled={!isEnabled()}
                onClick={() => updateWithdrawalDetails({
                  iwithdrawalName,
                  iwithdrawalEmail,
                  iwithdrawalMethod,
                  iwithdrawalRoutingNumber,
                  iwithdrawalAccountNumber,
                  code,
                })}
                className={`btn bg-indigo-500 hover:bg-indigo-600 text-white h-11 ${!isEnabled() ? 'cursor-not-allowed' : ''}`}
              >
                <span className="block">Update</span>
              </button>
            </div>
          </div>
        </div>
        );
  }


}

      export default WithdrawalMethod;
