import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';
import 'antd/dist/antd.css';

import { focusHandling } from 'cruip-js-toolkit';
import './charts/ChartjsConfig';

// Import pages
import Dashboard from './pages/Dashboard';
import Referrals from './pages/Referrals';
import Transactions from './pages/Transactions';
import Settings from './pages/Settings';

import CONSTANTS from './constants';
import Axios from './api/axios';

function App() {

  const location = useLocation();

  const [me, setMe] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [authId, setAuthId] = useState(null);
  const [connected, setConnected] = useState(false);
  const [withdrawalDetails, setWithdrawalDetails] = useState({});

  useEffect(() => {
    let id = new URLSearchParams(window.location.search).get('id');
    if (id) {
      window.localStorage.setItem('id', id);
      window.location.href = '/';
    }

    id = window.localStorage.getItem('id');

    if (id) {
      Axios({
        method: 'get',
        url: '/auth/me',
        headers: { 'x-auth': id }
      })
        .then(({ data }) => {
          const { me, notifications, withdrawalDetails } = data;

          setAuthId(id);
          setMe(me);
          setNotifications(notifications);
          setConnected(true);
          setWithdrawalDetails(withdrawalDetails);
        })
        .catch((e) => {

          // console.log(e);

          if (e.response) {
            const { status } = e.response;

            if (status === 401) {
              window.localStorage.removeItem('id');
              window.location.href = `${CONSTANTS.WEBSITE_URL}`;
            } else {
              setConnected(false);
            }
          } else {
            setConnected(false);
          }

        });
    } else {
      window.localStorage.removeItem('id');
      window.location.href = `${CONSTANTS.WEBSITE_URL}`;
    }
  }, []);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change


  const logout = () => {
    window.localStorage.removeItem('id');
    window.location.href = `${CONSTANTS.WEBSITE_URL}`;
  }

  return (
    <>{connected && (
      <Switch>
        <Route exact path="/">
          <Dashboard authId={authId} me={me} notifications={notifications} logout={logout} />
        </Route>
        <Route exact path="/referrals">
          <Referrals authId={authId} me={me} notifications={notifications} logout={logout} />
        </Route>
        <Route exact path="/transactions">
          <Transactions authId={authId} me={me} notifications={notifications} logout={logout} />
        </Route>
        <Route exact path="/settings">
          <Settings authId={authId} me={me} notifications={notifications} logout={logout} withdrawalDetails={withdrawalDetails} />
        </Route>
      </Switch>
    )}
    {
      connected || <p className="text-lg font-semibold text-gray-700">Loading..</p>
    }
    </>
  );
}

export default App;
