import React, { useState } from 'react';

function TwoAuthenticator({ me, setupTwoFactor }) {

  const [code, setCode] = useState('');

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 max-w-xl mx-auto">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-medium text-gray-800 text-base">Setup two factor authentication (required for withdrawals)</h2>
      </header>
      <div className="mb-2 px-8 pb-2">

        <div className="mb-2 text-center">
          <img className="inline" src={`https://chart.googleapis.com/chart?cht=qr&chl=${encodeURI(`otpauth://totp/Katario Affiliate (${me.email})?secret=${me.base32Secret}`)}&chs=300x300`} alt="qr code" />
        </div>

        <div className="mb-2">
          <label className="block text-gray-800 mb-1 text-center text-sm font-bold">
            SAVE YOUR TWO FACTOR AUTHENTICATION KEY (VERY IMPORTANT)
          </label>
          <input
            readOnly
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-xs text-center"
            id="name"
            type="text"
            placeholder=""
            value={me.base32Secret}
          />
        </div>

        <div className="mb-2 mt-6">
          <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
            Two Factor Authentication Code *
          </label>
          <input
            onChange={(e) => setCode(e.target.value)}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-lg"
            id="name"
            type="text"
            placeholder=""
            autoFocus={true}
            value={code}
          />
        </div>

        <div className="flex lg:space-x-3 pt-3 bg-white mt-2 justify-end">
          <button
            disabled={!code}
            onClick={() => setupTwoFactor(code)}
            className={`btn bg-indigo-500 hover:bg-indigo-600 text-white h-11 ${(!code) ? 'cursor-not-allowed' : ''}`}
          >
            <span className="block">Setup</span>
          </button>
        </div>

      </div>
    </div>
  );
}

export default TwoAuthenticator;
