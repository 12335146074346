import React from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { MDBDataTable } from 'mdbreact';

const ReferralsTable = ({ referrals }) => {
  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
      },
      {
        label: 'Product',
        field: 'product',
        sort: 'asc',

      },
      {
        label: 'Profit',
        field: 'profit',
        sort: 'asc',

      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',

      },
    ],
    rows: referrals.map((c) => ({ ...c, date: new Date(c.date).toLocaleString(), profit: `$${c.profit}` })),
  };

  return (
    <div>
      <MDBDataTable
        striped
        bordered
        hover
        small
        exportToCSV
        noRecordsFoundLabel="No data available"
        responsive
        displayEntries={false}
        data={data}
        entries={20}
        noBottomColumns
        className="text-gray-700 font-medium"
      />
    </div>
  );
}

export default ReferralsTable;